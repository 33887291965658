import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import BlockContent from "@sanity/block-content-to-react"
import { css } from "@emotion/core"

import PageHeader from "../components/PageHeader"

export const query = graphql`
  {
    sanityAbout {
      title
      aboutImage {
        imageUpload {
          asset {
            id
            url
          }
          hotspot {
            x
            y
          }
        }
        imageDescription
      }
      _rawAboutText(resolveReferences: { maxDepth: 5 })
      # _rawAboutImage(resolveReferences: { maxDepth: 5 })
    }
  }
`

function about({ data }) {
  const aboutText = data.sanityAbout._rawAboutText
  // const aboutImage = data.sanityAbout.aboutImage
  const aboutImageBackground = data.sanityAbout.aboutImage.imageUpload

  const blockRenderer = ({ node, children }) => {
    switch (node.style) {
      case "h1":
        return (
          <div>
            <h1>{children}</h1>
          </div>
        )
      case "h2":
        return (
          <div>
            <h2>{children}</h2>
          </div>
        )
      case "h3":
        return (
          <div>
            <h3>{children}</h3>
          </div>
        )
      case "h4":
        return (
          <div>
            <h4>{children}</h4>
          </div>
        )
      case "h5":
        return (
          <div>
            <h5>{children}</h5>
          </div>
        )
      case "h6":
        return (
          <div>
            <h6>{children}</h6>
          </div>
        )
      case "blockquote":
        return (
          <div>
            <blockquote>{children}</blockquote>
          </div>
        )
      default:
        return (
          <div>
            <p>{children}</p>
          </div>
        )
    }
  }

  console.log(aboutImageBackground)

  return (
    <Layout>
      <PageHeader backgroundImage={aboutImageBackground} />
      {/* <div>
        <img
          // css={imageStyles}
          css={css`
            margin-bottom: var(--size-5);
          `}
          // sizes="(min-width: 800px) 1000px, 100vw"
          srcSet={[
            urlFor(aboutImage.imageUpload.asset.id)
              .auto("format")
              .width(2000)
              .height(400)
              .fit("crop")
              .focalPoint(
                aboutImage.imageUpload.hotspot.x,
                aboutImage.imageUpload.hotspot.y
              )
              .url() + ` 2000w`,

            urlFor(aboutImage.imageUpload.asset.id)
              .auto("format")
              .width(800)
              .height(480)
              .fit("crop")
              .focalPoint(
                aboutImage.imageUpload.hotspot.x,
                aboutImage.imageUpload.hotspot.y
              )
              .url() + ` 800w`,
          ]}
          src={urlFor(aboutImage.imageUpload.asset.id)
            .auto("format")
            .width(800)
            .height(480)
            .fit("crop")
            .focalPoint(
              aboutImage.imageUpload.hotspot.x,
              aboutImage.imageUpload.hotspot.y
            )
            .url()}
          alt={aboutImage.imageDescription}
        />
      </div> */}
      <div
        className="sectionContent"
        css={css`
          max-width: 800px;
          margin-top: var(--size-5);
        `}
      >
        <h1>{data.sanityAbout.title}</h1>
        <BlockContent
          blocks={aboutText}
          serializers={{
            types: { block: blockRenderer },
          }}
          // className="projectContent"
        />
      </div>
    </Layout>
  )
}

export default about
